<template>
  <v-row>
    <v-col>
      <v-card class="rounded-lg py-1 px-2 shadow-regular">
        <v-container>
          <v-row>
            <v-card-title class="section-title mb-0">
              {{ `${monthTranslation} ${year}` }}
            </v-card-title>

            <v-col class="pa-4 d-flex justify-end">
              <IconButton
                class="icon-btn"
                icon="mdi-arrow-left"
                @click="previousMonth()"
              />
              <IconButton
                class="icon-btn ml-2"
                icon="mdi-arrow-right"
                @click="nextMonth()"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-card-text>
          <v-container class="mt-4">
            <v-row>
              <v-col
                class="calendar-header uppercase-header calendar-header-employee"
                cols="2"
              >
                <p class="mb-0">
                  {{ t('Msg_.employee') }}
                </p>
              </v-col>
              <v-col
                class="calendar-header uppercase-header pa-0"
                v-for="day of daysInMonth"
                :key="day"
              >
                <p class="ma-0 pa-0">
                  {{
                    `${helpers.getShortWeekDay(year, month, day, i18n.locale)}`
                  }}
                </p>
                <p class="ma-0 pa-0">{{ day }}</p>
              </v-col>
            </v-row>
            <v-row
              v-for="(
                item, calendarDaysIndex
              ) of absencesStore.absenceCalendarDays"
              :key="calendarDaysIndex"
            >
              <v-col class="calendar-cell-employee" cols="2">
                <p class="mb-0 employee-column">
                  {{ item.employeeName }}
                </p>
              </v-col>
              <v-col
                class="pa-0 calendar-cell"
                v-for="(day, daysInMonthIndex) in daysInMonth"
                :key="daysInMonthIndex"
                :class="checkDayClassByStatus(item.month, day)"
              >
                {{ getDayAbsenceAbbreviation(item.month, day) }}
              </v-col>
            </v-row>
            <v-row class="pt-4">
              <v-col class="d-flex pa-0 flex-row flex-wrap">
                <RegularBadge
                  class="mr-2 mt-2 px-2"
                  text-color="caribbeanRegular"
                  color="lowOpacityGreen"
                  :text="absenceTypesStatusText('Approved')"
                />
                <RegularBadge
                  class="mr-2 mt-2 px-2"
                  text-color="blueRegular"
                  color="lowOpacityBlue"
                  :text="absenceTypesStatusText('Requested')"
                />
                <div
                  v-for="(type, index) in absencesStore.absencesResources
                    .absenceTypes"
                  :key="index"
                >
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <RegularBadge
                          text-color="greyDark"
                          color="ashLight"
                          :text="t(`Msg_.absenceTypesAbbreviation[${type.id}]`)"
                          class="mr-2 mt-2 px-2 types-badge d-flex justify-center"
                        />
                      </div>
                    </template>
                    <span> {{ t(`Msg_.absenceTypes[${type.id}]`) }}</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import { useAbsencesStore } from '@/store/pinia/absences.store.js';
import helpers from '@/helpers/helpers';
import i18n from '@/i18n';
import IconButton from '../../shared/customComponents/buttons/icon-button.vue';
import languages from '../locales/languages';
import RegularBadge from '../../shared/customComponents/alertsBadges/regular-badge.vue';

const absencesStore = useAbsencesStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });
const emit = defineEmits(['previous-month', 'next-month']);

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  month: {
    type: [Number],
    required: false,
    default: null,
  },
  year: {
    type: [Number],
    required: false,
    default: null,
  },
  daysInMonth: {
    type: [Number],
    required: false,
    default: null,
  },
});

// --- Methods ---
const previousMonth = () => {
  emit('previous-month');
};

const nextMonth = () => {
  emit('next-month');
};

const checkDayClassByStatus = (month, day) => {
  const absenceDay = month.find((absence) => absence.day === day);
  const requestedStatus = absencesStore.absencesResources.requestsStatus.find(
    (status) => status.Name === 'Requested'
  );
  const approvedStatus = absencesStore.absencesResources.requestsStatus.find(
    (status) => status.Name === 'Approved'
  );
  return {
    'requested-cell': absenceDay && absenceDay.status === requestedStatus.Value,
    'approved-cell': absenceDay && absenceDay.status === approvedStatus.Value,
  };
};

const getDayAbsenceAbbreviation = (month, day) => {
  const absenceDay = month.find((absence) => absence.day === day);
  return absenceDay && absenceDay.type
    ? t(`Msg_.absenceTypesAbbreviation[${absenceDay.type}]`)
    : '';
};

const absenceTypesStatusText = (statusToTranslate) => {
  let absencesStatusTranslations = tm('Msg_.absenceTypesStatus');

  const status = absencesStore.absencesResources.requestsStatus.find(
    (requestStatus) => requestStatus.Name === statusToTranslate
  );
  return absencesStatusTranslations[status.Value];
};

// --- Computed ---
const monthTranslation = computed(() => {
  return t(`Action_.selectMonth[${props.month}]`);
});
</script>

<style lang="scss" scoped>
.calendar-cell {
  padding: 0px !important;
  text-align: center;
  font-size: 12px;
  border: 1px solid $ash-regular;
}

.calendar-cell-employee {
  padding: 0px !important;
  text-align: left;
  font-size: 12px;
}

.calendar-header {
  padding: 0px !important;
  word-break: 'break-spaces' !important;
  text-align: center;
  font-size: 11px;
}

.uppercase-header {
  line-height: 14px !important;
}

.approved-cell {
  background-color: $caribbean-regular;
  color: white;
}

.requested-cell {
  background-color: $blue-regular;
  color: white;
}

.types-badge {
  min-width: 40px;
}

.employee-column {
  color: $mine-black !important;
}
</style>
