<template data-testid="people-absences-export-absences-dialog">
  <v-dialog :value="displayExportDialog" persistent width="736">
    <v-card class="pa-8">
      <v-container>
        <v-row class="pb-8">
          <v-col
            class="heading-h3 pa-0"
            data-testid="people-absences-export-absences-dialog-title"
          >
            {{ t('Action_.buttons.export') }}
          </v-col>
          <v-col cols="auto" class="text-end pa-0">
            <v-icon
              data-testid="people-absences-export-absences-close-btn"
              size="28"
              cols="auto"
              @click="closeDialog()"
              color="mineBlack"
            >
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="pb-4">
          <v-col cols="6" class="pa-0">
            <DatePicker
              data-testid="people-absences-export-absences-month-picker"
              v-model="selectedDate"
              type="month"
              :clearable="false"
              :max="maxDate"
              :min="minDate"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row class="pt-8">
          <v-col class="d-flex justify-end pa-0">
            <v-btn
              data-testid="people-absences-export-absences-cancel-btn"
              class="main-action-variant-btn"
              @click="closeDialog()"
              :disabled="loadingStore.isPartialLoading"
            >
              {{ t('Action_.buttons.cancel') }}
            </v-btn>
            <v-btn
              data-testid="people-absences-export-absences-export-btn"
              class="main-action-btn ml-2"
              :disabled="loadingStore.isPartialLoading"
              :loading="loadingStore.isPartialLoading"
              @click="exportAbsences()"
            >
              {{ t('Action_.buttons.export') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue';
import { useAbsencesStore } from '@/store/pinia/absences.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import DatePicker from '@/components/shared/customComponents/inputs/date-picker.vue';
import languages from '../locales/languages';

const absencesStore = useAbsencesStore();
const loadingStore = useLoadingStore();
const emit = defineEmits(['close-export-dialog']);
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
const props = defineProps({
  displayExportDialog: {
    type: Boolean,
    required: true,
  },
  previousSelectedMonth: {
    type: Number,
    required: true,
  },
  previousSelectedYear: {
    type: Number,
    required: true,
  },
});

// --- Local Variables ---
const selectedDate = ref();
const minDate = ref();
const maxDate = ref();

// --- Methods ---
const setupDates = () => {
  const currentYear = new Date().getFullYear();

  // consider August of 2023 the min date allowed as its the start date of entries stored
  // consider December of the current year the max date allowed
  const min = new Date(2023, 7, 2);
  const max = new Date(currentYear, 12, 0);
  minDate.value = min.toJSON();
  maxDate.value = max.toJSON();

  // get the previous selected date in people absences dashboard
  const previousSelectedDate = new Date(
    props.previousSelectedYear,
    props.previousSelectedMonth - 1,
    2
  );

  // check if previousSelectedDate is in range of valid dates to set initial selected month for export. Else, sets current date
  if (
    min.valueOf() <= previousSelectedDate.valueOf() &&
    max.valueOf() >= previousSelectedDate.valueOf()
  ) {
    selectedDate.value = previousSelectedDate.toISOString().substring(0, 7);
  } else {
    selectedDate.value = new Date().toISOString().substring(0, 7);
  }
};

const closeDialog = () => {
  emit('close-export-dialog');
};

const exportAbsences = async () => {
  const selectedYear = new Date(selectedDate.value).getFullYear();
  const selectedMonth = new Date(selectedDate.value).getMonth();

  // first day of selected month
  const startDate = `${selectedYear}-${(selectedMonth + 1)
    .toString()
    .padStart(2, '0')}-01`;

  // Total days of selected month
  const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();

  // Last day of selected month
  const endDate = `${selectedYear}-${(selectedMonth + 1)
    .toString()
    .padStart(2, '0')}-${daysInMonth}`;

  const filename = `Abwesenheit_${startDate}_${endDate}.csv`;

  await absencesStore.exportAbsencesData(
    {
      startDate,
      endDate,
    },
    filename
  );

  closeDialog();
};

// --- Lifecycle Hooks ---
setupDates();
</script>

<style scoped lang="scss"></style>
