<template>
  <v-dialog
    :value="absencesStore.showAbsenceDialog"
    :retain-focus="false"
    persistent
    width="750"
    class="pa-4"
  >
    <v-card class="pa-4">
      <v-container v-if="!!absencesStore.absenceDetails">
        <v-row>
          <v-col>
            <v-card-title class="card-title pa-0 font-weight-bold">
              {{ `${t('Msg_.absenceRequestDetails')}` }}
            </v-card-title>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <RegularTextInput
              data-testid="absence-details-type-input"
              :label="t(`Msg_.absenceApplicationLabels.type`)"
              disabled
              :value="
                t(`Msg_.absenceTypes[${absencesStore.absenceDetails.type}]`)
              "
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="pt-0">
            <RegularTextInput
              data-testid="absence-details-start-date-input"
              :label="t('Msg_.absenceApplicationLabels.start')"
              disabled
              :value="
                helpers.formatShortDate(absencesStore.absenceDetails.startDate)
              "
            />
          </v-col>
          <v-col cols="6" class="pt-0">
            <RegularTextInput
              data-testid="absence-details-end-date-input"
              :label="t('Msg_.absenceApplicationLabels.end')"
              disabled
              :value="
                helpers.formatShortDate(absencesStore.absenceDetails.endDate)
              "
            />
          </v-col>
        </v-row>

        <v-row v-if="!!absencesStore.absenceDetails.comments">
          <v-col cols="12" class="pt-0">
            <TextArea
              data-testid="absence-details-comments-text-area"
              :label="t('Msg_.absenceApplicationLabels.comments')"
              disabled
              :value="absencesStore.absenceDetails.comments"
            />
          </v-col>
        </v-row>
        <v-row v-if="!!absencesStore.absenceDetails.uploadedDocumentId">
          <v-col cols="6" class="pt-0">
            <v-row>
              <v-col cols="12">
                <FileInput
                  data-testid="absences-details-upload-file-input"
                  class="disable-field"
                  :downloadable="true"
                  disabled
                  :clearable="false"
                  :label="t('Msg_.absenceApplicationLabels.upload')"
                  :value="absencesStore.absenceDetails.displayName"
                  :placeholder="
                    helpers.shrinkString(
                      absencesStore.absenceDetails.displayName
                    )
                  "
                  @display-document="interactWithDocument(false)"
                  @download-document="interactWithDocument(true)"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-container>
          <v-row>
            <v-col class="pl-0">
              <v-btn
                data-testid="absences-details-close-cancel-btn"
                class="cancel-variant-btn ml-0"
                @click="closeAbsenceDialog()"
              >
                {{ t('Action_.buttons.close') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import { useAbsencesStore } from '@/store/pinia/absences.store.js';
import FileInput from '@/components/shared/customComponents/inputs/file-input.vue';
import helpers from '@/helpers/helpers';
import languages from '../locales/languages';
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import TextArea from '@/components/shared/customComponents/inputs/text-area.vue';

const absencesStore = useAbsencesStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Methods ---
const interactWithDocument = (download) => {
  const id = absencesStore.absenceDetails.uploadedDocumentId;
  const name = absencesStore.absenceDetails.displayName;
  window.open(
    `${process.env.VUE_APP_LOGIN_API}/v2/api/documents/${id}/download?fileName=${name}&download=${download}`
  );
};

const closeAbsenceDialog = () => {
  absencesStore.showAbsenceDialog = false;
};

// --- Lifecycle hooks ---
onBeforeUnmount(() => {
  absencesStore.showAbsenceDialog = false;
});
</script>

<style scoped lang="scss"></style>
