<template>
  <v-container>
    <v-row>
      <v-col class="pt-7">
        <span class="heading-h3">{{ t('Title_.page.absences') }}</span>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-space-between align-center py-2 mt-0">
      <v-col :cols="$vuetify.breakpoint.xl ? 2 : 3">
        <DropdownInput
          data-testid="people-absences-select-hub-dropdown"
          v-model="hub"
          hide-details
          :items="absencesStore.hubList"
          :clearable="false"
          item-text="name"
          item-value="id"
          @change="updateAbsencesData(true)"
        />
      </v-col>
      <v-col cols="auto">
        <v-btn
          v-if="canUser(['Absences.Export.Overview'])"
          data-testid="people-absences-export-btn"
          class="main-action-btn"
          @click="setDisplayExportDialog(true)"
        >
          {{ t('Action_.buttons.export') }}
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="!loadingStore.isPartialLoading">
      <PeopleAbsencesCalendar
        v-if="canUser(['Absences.Schedule.List']) && hasParamsInformation"
        data-testid="people-absences-calendar"
        :month="month"
        :year="year"
        :days-in-month="daysInMonth"
        @next-month="changeMonth(+1)"
        @previous-month="changeMonth(-1)"
      />
      <PeopleAbsencesTable
        v-if="canUser(['Absences.Requests.List']) && hasParamsInformation"
        data-testid="people-absences-table"
        @update-table="getAbsencesTable(false)"
        @update-request="updateRequest($event)"
      />
    </div>
    <div v-else>
      <v-row>
        <v-col class="mt-16 d-flex justify-center align-center">
          <LoadingAnimation dense />
        </v-col>
      </v-row>
    </div>
    <PeopleAbsenceDetails data-testid="people-absences-details" />
    <PeopleAbsencesExportDialog
      v-if="displayExportDialog"
      data-testid="people-absences-export-dialog"
      :display-export-dialog="displayExportDialog"
      :previous-selected-month="month"
      :previous-selected-year="year"
      @close-export-dialog="setDisplayExportDialog(false)"
    />
  </v-container>
</template>

<script setup>
import { computed, ref, onBeforeUnmount } from 'vue';
import { useAbsencesStore } from '@/store/pinia/absences.store';
import { usePermissions } from '@/helpers/composables/permissions.js';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import helpers from '@/helpers/helpers';
import i18n from '@/i18n';
import PeopleAbsenceDetails from './people-absence-details.vue';
import PeopleAbsencesCalendar from './people-absences-calendar.vue';
import PeopleAbsencesTable from './people-absences-table.vue';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';
import { useI18n } from 'vue-i18n-bridge';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import languages from '../locales/languages';
import LoadingAnimation from '@/components/shared/customComponents/loadingAnimation/loading-animation.vue';
import PeopleAbsencesExportDialog from './people-absences-export-dialog.vue';

const { canUser } = usePermissions();
const { t } = useI18n({ locale: 'en', messages: languages });
const { trackPage } = amplitudeComposable();
const absencesStore = useAbsencesStore();
const loadingStore = useLoadingStore();

// --- Local Variables ---
const hub = ref(null);
const month = ref(null);
const year = ref(null);
const daysInMonth = ref(null);
const displayExportDialog = ref(false);

// --- Methods ---
const getAbsencesInformation = async () => {
  await getAbsencesResources();
  setInitialDate();
  updateAbsencesData();
};

const getAbsencesResources = async () => {
  await absencesStore.getAbsencesResources(true);
  await getHubInformation();
};

const updateAbsencesData = (resetTablePage = false) => {
  if (resetTablePage) absencesStore.resetTablePage();
  getAbsencesTable();
  getCalendarDays();
  trackPage(hub.value);
};

const getAbsencesTable = (loading = true) => {
  const params = {
    year: year.value,
    month: month.value,
    hub: hub.value,
    loading,
  };
  absencesStore.getAbsencesListByHubAndMonth(params);
};

const getCalendarDays = () => {
  const params = {
    year: year.value,
    month: month.value,
    hub: hub.value,
  };
  absencesStore.getCalendarAbsenceDays(params);
};

const getHubInformation = async () => {
  const office = await absencesStore.getUserMainHub();

  await absencesStore.getHubList();

  if (absencesStore.hubList?.length > 1) {
    const officeInHubList = absencesStore.hubList.find(
      (hub) => hub.id === office
    );

    if (officeInHubList) {
      hub.value = office;
    } else {
      hub.value = absencesStore.hubList[0].id;
    }
  } else if (absencesStore.hubList[0]) {
    hub.value = absencesStore.hubList[0].id || null;
  }
};

const setInitialDate = () => {
  month.value = parseInt(
    new Date().toLocaleString(i18n.locale, {
      month: 'numeric',
    })
  );
  year.value = parseInt(
    new Date().toLocaleString(i18n.locale, {
      year: 'numeric',
    })
  );
  daysInMonth.value = helpers.getDaysInMonth(year.value, month.value);
};

const changeMonth = (monthToChange) => {
  // Decreases 1 to get month index as JS Date API uses months as index from 0 to 11
  const currentMonthIndex = month.value - 1;
  const updateDate = new Date(year.value, currentMonthIndex + monthToChange);

  // Adds 1 to convert back from index to numeric month number
  month.value = updateDate.getMonth() + 1;
  year.value = updateDate.getFullYear();

  daysInMonth.value = helpers.getDaysInMonth(year.value, month.value);
  updateAbsencesData();
};

const setDisplayExportDialog = (displayDialog) => {
  displayExportDialog.value = displayDialog;
  if (displayExportDialog.value) {
    trackPage(hub.value, { routeName: 'people.absences.export' });
  }
};

const updateRequest = async (event) => {
  const status = absencesStore.absencesResources.requestsStatus.find(
    (status) => status.Name === event.status
  );

  await absencesStore.changeAbsenceRequestStatus({
    requestId: event.id,
    status: status.Value,
  });
  updateAbsencesData();
};

// --- Computed ---
const hasParamsInformation = computed(() => {
  return !!month.value && !!year.value && !!daysInMonth.value;
});

// --- Lifecycle hooks ---
getAbsencesInformation();

onBeforeUnmount(() => {
  absencesStore.$reset();
});
</script>

<style lang="scss" scoped></style>
