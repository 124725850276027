import { render, staticRenderFns } from "./people-absences-export-dialog.vue?vue&type=template&id=76f0be71&scoped=true&data-testid=people-absences-export-absences-dialog&"
import script from "./people-absences-export-dialog.vue?vue&type=script&setup=true&lang=js&"
export * from "./people-absences-export-dialog.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76f0be71",
  null
  
)

export default component.exports