<template>
  <v-row>
    <v-col>
      <v-card class="rounded-lg py-1 px-2 shadow-regular">
        <v-card-title class="section-title mb-0">
          {{ t('Title_.subTitle.futureVacations') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <SearchInput
                :label="t('Msg_.searchRequest')"
                v-model="absencesStore.filters.searchPattern"
                :placeholder="t('Msg_.search')"
                search-on-typing
                @input="filterTable"
              />
            </v-col>
            <v-col cols="3">
              <DropdownInput
                :items="selectAbsenceTypeOptions"
                v-model="absencesStore.filters.type"
                :label="t('Msg_.absenceTableHeaders.type')"
                @change="updateTable"
              />
            </v-col>
            <v-col cols="3">
              <DropdownInput
                :items="selectRequestStatusOptions"
                v-model="absencesStore.filters.status"
                :label="t('Msg_.absenceTableHeaders.status')"
                @change="updateTable"
              />
            </v-col>
          </v-row>
          <v-data-table
            hide-default-footer
            :headers="headers"
            :no-data-text="t('Msg_.noData')"
            :items="absencesStore.absenceList"
            :items-per-page="absencesStore.pagination.pageSize"
            :loading="loadingStore.isPartialLoading"
          >
            <template
              #[`item.employee`]="{ item }"
              data-testid="absences-table-employee-field"
            >
              <p class="mb-0 text-left employee-column">
                {{ item.employeeName }}
              </p>
            </template>

            <template
              #[`item.type`]="{ item }"
              data-testid="absences-table-type-field"
            >
              <div
                @click="
                  absencesStore.displayExistingRequestAbsenceDialog(item.id)
                "
              >
                <a>
                  {{ t(`Msg_.absenceTypes[${item.type}]`) }}
                </a>
              </div>
            </template>
            <template
              #[`item.startDate`]="{ item }"
              data-testid="absences-table-start-date-field"
            >
              <p class="mb-0">
                {{ helpers.formatShortDate(item.startDate) || '-' }}
              </p>
            </template>
            <template
              #[`item.endDate`]="{ item }"
              data-testid="absences-table-end-date-field"
            >
              <p class="mb-0">
                {{ helpers.formatShortDate(item.endDate) || '-' }}
              </p>
            </template>
            <template
              #[`item.numberAbsences`]="{ item }"
              data-testid="absences-table-number-absences-days-field"
            >
              <p class="mb-0">
                {{ item.numberAbsences }}
              </p>
            </template>

            <template
              #[`item.requestedAt`]="{ item }"
              data-testid="absences-table-requested-at-field"
            >
              <p class="mb-0">
                {{ helpers.formatShortDate(item.requestedAt) || '-' }}
              </p>
            </template>
            <template
              #[`item.status`]="{ item }"
              data-testid="absences-table-status-chip"
            >
              <v-chip
                :color="absenceLabelColors(item.status).color"
                :text-color="absenceLabelColors(item.status).text"
                small
                class="font-weight-bold"
              >
                {{ t(`Msg_.absenceTypesStatus[${item.status}]`) }}
              </v-chip>
            </template>
            <template
              #[`item.action`]="{ item }"
              data-testid="absences-table-actions-field"
            >
              <IconButton
                data-testid="absences-table-reject-button"
                v-if="canUpdateRequestTo('Rejected', item.status)"
                class="icon-btn"
                color="redDanger"
                icon="mdi-close"
                :border="false"
                icon-size="21"
                @click="
                  emit('update-request', { status: 'Rejected', id: item.id })
                "
                :disabled="loadingStore.isPartialLoading"
              />
              <IconButton
                data-testid="absences-table-approve-button"
                v-if="canUpdateRequestTo('Approved', item.status)"
                class="icon-btn"
                color="caribbeanRegular"
                icon="mdi-check"
                :border="false"
                icon-size="21"
                @click="
                  emit('update-request', { status: 'Approved', id: item.id })
                "
                :disabled="loadingStore.isPartialLoading"
              />
              <IconButton
                data-testid="absences-table-delete-button"
                v-if="canUpdateRequestTo('Canceled', item.status)"
                class="icon-btn"
                color="redDanger"
                icon="mdi-delete"
                :border="false"
                icon-size="21"
                @click="displayConfirmationDialog(item.id)"
                :disabled="loadingStore.isPartialLoading"
              />
            </template>
          </v-data-table>
          <Pagination
            type="wide"
            v-model="absencesStore.pagination"
            @update-list="updateTable"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import { computed, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import { useAbsencesStore } from '@/store/pinia/absences.store.js';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { usePermissions } from '@/helpers/composables/permissions.js';
import { usePopupDialogStore } from '@/store/pinia/popup-dialog.store.js';
import DropdownInput from '../../shared/customComponents/inputs/dropdown-input.vue';
import helpers from '@/helpers/helpers';
import IconButton from '../../shared/customComponents/buttons/icon-button.vue';
import languages from '../locales/languages';
import SearchInput from '../../shared/customComponents/inputs/search-input.vue';
import Pagination from '@/components/shared/customComponents/paginations/pagination.vue';

const absencesStore = useAbsencesStore();
const loadingStore = useLoadingStore();
const popupDialogStore = usePopupDialogStore();
const { canUser } = usePermissions();
const { t, tm } = useI18n({ locale: 'en', messages: languages });
const emit = defineEmits(['update-table', 'update-request']);

// --- Methods ---
const canUpdateRequestTo = (statusToChange, currentStatus) => {
  switch (statusToChange) {
    case 'Rejected':
    case 'Approved':
      return (
        checkAbsenceStatus(currentStatus, 'Requested') &&
        canUser(['Absences.Requests.Review'])
      );
    case 'Canceled':
      return (
        checkAbsenceStatus(currentStatus, 'Approved') &&
        canUser(['Absences.Requests.Cancel'])
      );
    default:
      return false;
  }
};

const checkAbsenceStatus = (statusToCheck, absenceStatusType) => {
  const requestStatus = absencesStore.absencesResources.requestsStatus.find(
    (status) => status.Name === absenceStatusType
  );
  return requestStatus.Value === statusToCheck;
};

const displayConfirmationDialog = (requestId) => {
  const params = {
    type: 'confirmation',
    title: t('Action_.dialog.cancelNotApprovedAbsenceRequest.title'),
    message: t('Action_.dialog.cancelNotApprovedAbsenceRequest.body'),
    reinforcement: t(
      'Action_.dialog.cancelNotApprovedAbsenceRequest.reinforcement'
    ),
    actionBtnText: t('Action_.buttons.proceed'),
    actionBtn: () =>
      emit('update-request', { status: 'Canceled', id: requestId }),
  };
  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const filterTable = () => {
  if (
    absencesStore.filters.searchPattern.length >= 3 ||
    absencesStore.filters.searchPattern.length === 0
  ) {
    updateTable();
  }
};

const updateTable = () => {
  validatePaginationData();
  emit('update-table');
};

const validatePaginationData = () => {
  absencesStore.pagination.length = !isNaN(absencesStore.pagination.length)
    ? absencesStore.pagination.length
    : 1;
};

const absenceLabelColors = (statusToDisplay) => {
  const requestStatus = absencesStore.absencesResources.requestsStatus.find(
    (status) => status.Value === statusToDisplay
  );
  return absencesStore.absenceStatusLabelsColors[requestStatus.Name];
};

// --- Computed ---
const selectAbsenceTypeOptions = computed(() => {
  const translations = tm('Msg_.absenceTypes');

  const selectTypes = absencesStore.absencesResources.absenceTypes.map(
    (type) => {
      return {
        text: translations[type.id],
        value: type.id,
      };
    }
  );
  return selectTypes;
});

const selectRequestStatusOptions = computed(() => {
  const translations = tm('Msg_.absenceTypesStatus');

  const requestsStatus = absencesStore.absencesResources.requestsStatus.filter(
    (status) => status.Name === 'Approved' || status.Name === 'Requested'
  );
  return helpers.translateAndAdaptToVuetify(translations, requestsStatus);
});

const headers = computed(() => {
  return [
    {
      text: tm('Msg_.absenceTableHeaders')['employee'],
      value: 'employee',
      class: 'uppercase-header',
      cellClass: 'pl-0',
      align: 'center',
      sortable: false,
    },
    {
      text: tm('Msg_.absenceTableHeaders')['type'],
      value: 'type',
      class: 'uppercase-header',
      align: 'center',
      sortable: false,
    },

    {
      text: tm('Msg_.absenceTableHeaders')['start'],
      value: 'startDate',
      class: 'uppercase-header',
      align: 'center',
      sortable: false,
    },
    {
      text: tm('Msg_.absenceTableHeaders')['end'],
      value: 'endDate',
      class: 'uppercase-header',
      align: 'center',
      sortable: false,
    },
    {
      text: tm('Msg_.absenceTableHeaders')['days'],
      value: 'numberAbsences',
      class: 'uppercase-header',
      align: 'center',
      sortable: false,
    },
    {
      text: tm('Msg_.absenceTableHeaders')['requestedOn'],
      value: 'requestedAt',
      class: 'uppercase-header',
      align: 'center',
      sortable: false,
    },
    {
      text: tm('Msg_.absenceTableHeaders')['status'],
      value: 'status',
      class: 'uppercase-header',
      align: 'center',
      sortable: true,
    },
    {
      text: tm('Msg_.absenceTableHeaders')['action'],
      value: 'action',
      class: 'uppercase-header',
      align: 'center',
      sortable: false,
    },
  ];
});
</script>

<style lang="scss" scoped>
.uppercase-header {
  line-height: 14px !important;
}

.v-data-table::v-deep th {
  color: $grey-regular !important;
  font-size: 11px !important;
}

.v-data-table::v-deep td.text-center {
  font-size: 12px !important;
}

.v-data-table::v-deep p.employee-column {
  color: $mine-black !important;
}
</style>
